export const FIRESTORE_PRODUCTS_TABLE = "products";
export const FIRESTORE_ORDERS_TABLE = "orders";
export const FIRESTORE_DISCOUNT_CODES_TABLE = "discountCodes";
export const FIRESTORE_FEATURED_PRODUCTS_TABLE = "featuredProducts";
export const FIRESTORE_RECIPES_TABLE = "recipes";
export const FIRESTORE_USERS_TABLE = "users";
export const FIRESTORE_ORDER_LIMITS_TABLE = "orderLimits";
export const FIRESTORE_CATEGORIES_TABLE = "categories";
export const FIRESTORE_MARKETING_UNSUBSCRIPTIONS_TABLE =
  "marketingUnsubscriptions";
export const FIRESTORE_LOYALTY_USERS_TABLE = "loyaltyUsers";
export const FIRESTORE_LOYALTY_CLAIMS_TABLE = "loyaltyClaims";
export const FIRESTORE_LOYALTY_REWARDS_TABLE = "loyaltyRewards";
export const FIRESTORE_USER_FEEDBACK_TABLE = "userFeedback";
export const FIRESTORE_INSTAGRAM_POSTS_TABLE = "instagramPosts";
export const FIRESTORE_BLOG_POSTS_TABLE = "blogPosts";
export const FIRESTORE_GIFT_CARDS_TABLE = "giftCards";
export const FIRESTORE_VACANCY_APPLICATIONS_TABLE = "vacancyApplications";
